import actions from './propertyActions'

const schema = [
  {
    component: 'div',
    class: 'tw-my-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        type: 'checkbox',
        name: 'is_newly_built',
        label: 'Nieuwbouw',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'checkbox',
        name: 'is_luxury',
        label: 'Luxevastgoed',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'checkbox',
        name: 'has_development_potential',
        label: 'Ontwikkelingspotentieel',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      }
    ]
  }
]

export default {
  heading: 'Kenmerken',
  schema,
  actions
}
